.blog {
    .input-group {
        // border: none !important;
        // background-color: white;
        // border-radius: 50px;

        input {
            border: none !important;
            background-color: transparent !important;
            color: rgba(1, 7, 37, 1) !important;

            &::placeholder {
                color: #96949F !important;
            }
        }

        span {
            border: none !important;
            background-color: transparent !important;
        }
    }

    .nav-pills .nav-link {
        color: rgba(0, 0, 0, 1) !important;
        background-color: transparent !important;
        // border: 2px solid #1D59F9;
        border-radius: 22px;
        min-width: 80px;
        margin: 3px;
        padding: 8px 15px !important;
    }

    .nav-pills .nav-link.active {
        color: white !important;
        background-color: rgba(3, 40, 238, 1) !important;

    }

    .paginate-blog {
        button {
            color: rgba(166, 166, 166, 1) !important; 
            font-size: 18px !important;
            font-family: 'DM Sans', sans-serif !important;

        }

        .Mui-selected {
            background-color: transparent !important;
            color: rgba(0, 30, 192, 1) !important;

            &:hover {
                background-color: transparent !important;

            }
        }

        .MuiPaginationItem-previousNext {


            color: white !important;
            background-color: rgba(0, 30, 192, 1) !important;

        }



    }
}
@media (max-width:768px){
    .blog {.nav-pills .nav-link {
       width: 100% !important;
    }}
}