.top-navbar {
  ul {
    li {
      list-style: none;
      padding-inline: 8px;
      a {
        font-weight: 500 !important;
        color: black;
        font-size: 18px;
      }

      a.active {
        color: rgba(27, 79, 248, 1) !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .top-navbar {
    ul {
      .nav-list {
        border-bottom: 1.5px solid rgb(220, 220, 220);
        padding: 12px;

        // .szh-menu-container {
        //   border: 0px solid rgb(220, 220, 220) !important;
        // }
      }
    }
  }
}
