.login-form {
  .input-group {
    span {
      background-color: transparent;
    }

    input {
      color: rgba(15, 23, 42, 1);

      &::placeholder {
        color: rgba(148, 163, 184, 1);
      }
    }
  }
}
