.contact-sale {
    background-color: #111019;

    .form-select {
        padding: 12px;
        border: 1.5px solid #BBB6C6;

        &:focus {
            box-shadow: none !important;
        }
    }

    label {
        color: #6C6684 !important;
    }

    input,
    textarea {
        color: rgba(0, 0, 0, 1) !important;
        resize: none;
        padding: 12px;
        border: 1.5px solid #BBB6C6;

        &:focus {
            background-color: rgba(247, 247, 248, 1);

        }

        &::placeholder {
            color: rgba(0, 0, 0, 1) !important;
        }
    }
    input[type="checkbox"]{
        transform: scale(.8) !important;
    }
}
.contact-us {
    .form-select{
        background-color: rgba(247, 247, 248, 1);

        &:focus{
            box-shadow: none !important;
        }
    }
    label {
        color: rgba(0, 0, 0, 1) !important;
    }

    input,
    textarea {
        color: rgba(0, 0, 0, 1) !important;
        resize: none;
        background-color: rgba(247, 247, 248, 1);

        &:focus {
            background-color: rgba(247, 247, 248, 1);

        }

        &::placeholder {
            color: rgba(0, 0, 0, 1) !important;
        }
    }
}

// .contact-accordin {



//     .accordion-button:not(.collapsed) {

//         /* box-shadow: none; */
//         background-color: transparent !important;
//     }



//     // .accordion-item:not(:first-of-type) {
//     // border-top: 1px solid #DBDBDB !important;
//     // }

//     .accordion-button::after {
//         background-image: none !important;
//         content: "+" !important;
//         color: rgba(0, 0, 0, 1) !important;
//         text-align: center;
//         display: flex !important;
//         align-items: center;
//         justify-content: center;
//         font-size: 38px;
//         font-weight: 200;
//     }

//     .accordion-button:not(.collapsed)::after {
//         background-image: none !important;
//         content: "-" !important;
//     }
// }

// .planePricing-accordin {
//     .accordion-item {
//         background-color: #D1D1F7 !important;
//     }

//     .accordion-button::after {
//         background-image: none !important;
//         font-family: 'fontAwesome' !important;
//         content: "\f063" !important;
//         font-size: 20px;
//         color: #3D2E7C !important;

//     }

//     .accordion-button:not(.collapsed)::after {
//         content: "\f063" !important;
//         font-family: 'fontAwesome' !important;

//     }
// }