.planet-img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 530px;
}
.fotter-line{
    &::after{
        content: '';
        width: -webkit-fill-available;
        height: 2px;
        background-color: black;
        display: flex;
        margin-top: 12px;
    }
}
.fotter-line3{
    &::after{
        content: '';
        width: -webkit-fill-available;
        height: 2px;
        background-color: transparent;
        display: flex;
        margin-top: 12px;
    }
}
.fotter-line2::after {
    content: "";
    transform: translate(-12px, 0px);
    width: 104px;
    height: 2px;
    background-color: black;
    display: flex;
    margin-top: 12px;
}